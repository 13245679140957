import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const preloadWizard = () => import('../plan-genius-wizard/PlanGeniusWizard');
  const handleGetStarted = () => {
    window.localStorage.removeItem('pg-active-step');
    window.localStorage.removeItem('pg-form-state');
    navigate('/wizard');
  }

  useEffect(() => {
    preloadWizard();
  }, [])
  
  return <Flex height="100vh" flexDirection="column" justifyContent="space-between" backgroundColor="blackAlpha.600" pb={[24, 24, 0]}>
    <Flex justifyContent="center" alignItems="center" flexDirection="column" width="100%" mt={6}>
      <Image src="/plan_genius_landing.png" width={["400px", "450px", "500px"]} alt="Plan Genius" />
    </Flex>
    <video autoPlay loop muted playsInline className="background-video">
      <source src="plangenius.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <Grid placeItems="center" pb={16}>
      <Button backgroundColor="blue.900" _hover={{ backgroundColor: "blue.800"}} color="white" border="1px solid white" size="lg" fontSize="xl" px={12} py={8} onClick={handleGetStarted}>{t("landing.getStarted")}</Button>
      <Flex alignItems="flex-start" flexDirection="column" width={["90%", "90%", "80%", "80%", "60%"]} mt={8} gap={4}>
        <Flex gap={4} alignItems="center">
          <FontAwesomeIcon icon={faCheck} color="green" size="3x" />
          <Text color="gray.300" fontWeight="medium" fontSize={["xl", "xl", "2xl", "2xl"]}>{t("landing.bullet1")}</Text>
        </Flex>
        <Flex gap={4} alignItems="center">
          <FontAwesomeIcon icon={faCheck} color="green" size="3x" />
          <Text color="gray.300" fontWeight="medium" fontSize={["xl", "xl", "2xl", "2xl"]}>{t("landing.bullet2")}</Text>
        </Flex>
      </Flex>
    </Grid>
  </Flex>
}
